import React from 'react'
import Helmet from 'react-helmet'

import StartProject from '../parts/StartProject'
import Footer from '../parts/Footer'
import OurValues from '../parts/OurValues'
import AlertBar from '../parts/AlertBar'

// import Actor from '../components/Actor'
import Section from '../components/Section'
import Article from '../components/Article'
import VerticalSpacer from '../components/VerticalSpacer'
// import ThumbnailGallery from '../components/ThumbnailGallery'
// import PageWidthWrapper from '../components/PageWidthWrapper'

import GradientBar, { ReverseGradientBar } from '../components/GradientBar'

// import photo1 from '../assets/sectionImages/1.jpg'

import home_simple from '../assets/premium/home_simple.jpg'
import home_vision from '../assets/premium/home_vision.jpg'
import home_mission from '../assets/premium/home_mission.jpg'

import home_simple2x from '../assets/premium2x/home_simple.jpg'
import home_vision2x from '../assets/premium2x/home_vision.jpg'
import home_mission2x from '../assets/premium2x/home_mission.jpg'

const commonButtonProps = {
  pill: true,
  size: 'sm',
  className: 'white',
  href: 'mailto:hello@softylabs.com',
}
// const industries = [
//   {
//     src: photo1,
//   },
//   {
//     src: photo1,
//   },
//   {
//     src: photo1,
//   },
//   {
//     src: photo1,
//   },
//   {
//     src: photo1,
//   },
//   {
//     src: photo1,
//   },
// ]
export default class Home extends React.Component {
  render() {
    return (
      <>
        <Helmet title="Home" />
        <Section photo={home_simple} photo2x={home_simple2x}>
          <>
            <VerticalSpacer />

            <Article
              renderHeader={(text) => <h1>{text}</h1>}
              header={'Software outsourcing made simple'}
              buttonProps={commonButtonProps}
              buttonText="Contact us"
              description={'Choose your next IT outsourcing partner with confidence.'}
            />
            <VerticalSpacer />
            <VerticalSpacer />
          </>
        </Section>
        <AlertBar />
        {/* <PageWidthWrapper>
          <ThumbnailGallery header="We have experience in several industries:" items={industries} />
          <div className='center600'>
            <Article
              header={'Long time partnership'}
              buttonProps={{...commonButtonProps, href: '/CaseStudy'}}
              buttonText="View case study"
              description2={
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, cepteur sint occaecat cupidatat non proidei officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, cepteur sint occaecat cupidatat non proidei officia deserunt mollit anim id est laborum.'
              }
            >
              <Actor
                photo={photo1}
                name='Jan Kowalski'
                position='Bardzo mily klient'
              />
            </Article>
          </div>
        </PageWidthWrapper>
        <ReverseGradientBar /> */}
        <VerticalSpacer />

        <Section inverted photo={home_vision} photo2x={home_vision2x}>
          <>
            <VerticalSpacer />
            <VerticalSpacer />
            <Article header={'Vision'} subheader={'01_'} subheaderClassName="lightBlueText">
              <p>
                We want to be the preferred partner for
                <br></br>
                sourcing highly skilled software developers
                <br></br>
                to increase our client’s profitability while
                <br></br>
                maintaining our shared focus on people,
                <br></br>
                ethics and the work environment.
              </p>
            </Article>
            <VerticalSpacer />
          </>
        </Section>
        <Section photo={home_mission} photo2x={home_mission2x}>
          <>
            <VerticalSpacer />
            <Article
              header={'Mission'}
              subheader={'02_'}
              subheaderClassName="lightBlueText"
              description={
                'We are a professional and responsible software development company that creates value for our clients and employees'
              }
            />
            <VerticalSpacer />
            <VerticalSpacer />
          </>
        </Section>
        <GradientBar />
        <OurValues />
        <StartProject />
        <Footer />
      </>
    )
  }
}
