import React from 'react'
import Helmet from 'react-helmet'

import StartProject from '../parts/StartProject'
import Footer from '../parts/Footer'
import Steps from '../parts/Steps'

import Section from '../components/Section'
import Article from '../components/Article'
import GradientBar from '../components/GradientBar'
import VerticalSpacer from '../components/VerticalSpacer'

import howwework from '../assets/premium/howwework.jpg'
import howwework2x from '../assets/premium2x/howwework.jpg'


const commonButtonProps = {
  pill: true,
  size: 'sm',
  className: 'white',
  href: 'mailto:hello@softylabs.com',
}

export default (props) => (
  <>
    <Helmet title="How We Work" />
    <Section photo={howwework} photo2x={howwework2x}>
      <>
        <VerticalSpacer />

        <Article
          header={'How we work'}
          buttonProps={commonButtonProps}
          buttonText="Contact us"
          description={'We create software for your company in simple and effective way'}
        />
        <VerticalSpacer />
        <VerticalSpacer height={20} />
      </>
    </Section>

    <Steps />

    <GradientBar />

    {/* <Section photo={safari} maxWidth={400}>
      <Article
        header={'Marketing and design team took control of their website'}
        renderAboveHeader={() => <VerticalSpacer height={20}/>}
        renderHeader={(title) => <h3>{title}</h3>}
        subheader={'Case Study'.toUpperCase()}
        buttonProps={{...commonButtonProps, href: '/CaseStudy'}}
        buttonText="Learn more"
        description={
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, cepteur sint occaecat cupidatat non proidei officia deserunt mollit anim id est laborumLorem ipsum dolor sit amet, consectetur adipiscing elit, cepteur sint occaecat cupidatat non proidei officia deserunt mollit anim id est laborum'
        }
      />
    </Section>
    <LineSeparator /> */}

    <StartProject />
    <Footer />
  </>
)
