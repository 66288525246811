import React from 'react'
import Helmet from 'react-helmet'

import StartProject from '../parts/StartProject'
import Footer from '../parts/Footer'

import Section from '../components/Section'
import Article from '../components/Article'
import LineSeparator from '../components/LineSeparator'
import VerticalSpacer from '../components/VerticalSpacer'
import StoryList from '../parts/StoryList'
import GradientBar from '../components/GradientBar'

import pk from '../assets/logos/pk.png'
import swiss from '../assets/logos/swiss.jpg'

import zpp from '../assets/logos/zpp.png'

import ourstory_team from '../assets/premium/ourstory_team.jpg'
import ourstory_team_2 from '../assets/premium/ourstory_team_2.jpg'
import ourstory_team_3 from '../assets/premium/ourstory_team_3.jpg'
import ourstory_team_4 from '../assets/premium/ourstory_team_4.jpg'

import ourstory_team2x from '../assets/premium2x/ourstory_team.jpg'
import ourstory_team_22x from '../assets/premium2x/ourstory_team_2.jpg'
import ourstory_team_32x from '../assets/premium2x/ourstory_team_3.jpg'
import ourstory_team_42x from '../assets/premium2x/ourstory_team_4.jpg'

const partners = [
  {
    src: pk,
    href: 'https://www.tu.koszalin.pl/',
  },
  {
    src: zpp,
    href: 'https://www.zzpp.net.pl/',
  },
  {
    src: swiss,
    href: 'https://swisschamber.pl/en/home-page/',
  },
]
export default () => (
  <>
    <Helmet title="Our story" />
    <Section photo={ourstory_team} photo2x={ourstory_team2x}>
      <>
        <VerticalSpacer />
        <VerticalSpacer />

        <Article
          header={'About us'}
          description={
            'SoftyLabs is a dynamic company providing modern development services for clients from various economic sectors.'
          }
        />
        <VerticalSpacer />
        <VerticalSpacer />
      </>
    </Section>
    <StoryList />
    <Section inverted photo={ourstory_team_2} photo2x={ourstory_team_22x}>
      <>
        <VerticalSpacer />
        <Article description={'Operationally, we adhere to two foundations locality and a long-term approach:'}>
          <li>
            Our employees remain part of local <br></br> communities and SoftyLabs often supports <br></br> the
            initiatives they are part of
          </li>
          <li>
            We anticipate the consequences of actions <br></br> in implemented projects, bearing in mind <br></br> the
            interests of our clients and SoftyLabs <br></br> - people who strive for them
          </li>
        </Article>
        <VerticalSpacer />
      </>
    </Section>
    <VerticalSpacer />
    <Section photo={ourstory_team_3} photo2x={ourstory_team_32x}>
      <>
        <VerticalSpacer />
        <Article>
          <VerticalSpacer height={20} />
          <p>
            We are a company where the potential of our employees is a key resource.
            <br></br>
            We don’t ask: “What if we train them and they leave?”
            <br></br>
            We ask: “What if we don’t and they stay?!”
          </p>
          <p>What kind of projects we love?</p>
          <li>The ones that carry weight and change the world</li>
          <li>Projects where relationship and mutual trust matter</li>
          <li>Full of challenges thanks to which our specialists can show their highest quality</li>
        </Article>
        <VerticalSpacer />
      </>
    </Section>
    <GradientBar />
    <Section photo={ourstory_team_4} photo2x={ourstory_team_42x}>
      <>
        <VerticalSpacer />
        <Article header={'Our partners in local initiatives'}>
          <>
            <p>
              We give students opportunity to gain experience and take first steps in IT industry.
              <br></br>
              We inspire entrepreneurs to digitize their businesses.
              <br></br>
              Together, we build basis for bold initiatives that will change region future.
            </p>
            <div className="gridGallery_content">
              {partners.map((item) => (
                <a className="partnerLogoLink" href={item.href}>
                  <img className="gridGallery_image" src={item.src} />
                </a>
              ))}
            </div>
          </>
        </Article>
        <VerticalSpacer />
      </>
    </Section>
    <LineSeparator />
    <StartProject />
    <Footer />
  </>
)
