import React from 'react'
import { Route, Link } from 'react-router-dom'
import styled from 'styled-components'

const NavLink = styled.div`
  margin: 5px 10px;
  a {
    transition: color 0.2s, border-bottom-color 0.2s;
    font-weight: 500;
    padding-bottom: 3px;
    border-bottom: ${({noStyles}) => (noStyles ? '' : '2px solid')};
    border-bottom-color: ${(props) => (props.active ? '#1f3dc3' : 'transparent')};
    &:hover {
      color: red
    }
  }
`

export default ({ path, title, NavComponent }) => (
  <Route
    path={path}
    children={({ match }) => (
      <NavLink active={match} noStyles={NavComponent != null}>
        <Link to={path}>{NavComponent != null ? <NavComponent /> : title}</Link>
      </NavLink>
    )}
  />
)
