import React from 'react'
import { Badge } from 'shards-react'

export default class List extends React.Component {
  render() {
    const { header, headerClassName, items } = this.props
    return (
      <div className="list">
        <Badge pill theme='light' className='listHeader'>{header}</Badge>
        <ul>
        {items.map((item) => <li>{item}</li>)}
        </ul>
      </div>
    )
  }
}
