import React from 'react'
import PageWidthWrapper from '../components/PageWidthWrapper'
import pin from '../assets/pin.png'

import { Badge } from 'shards-react'
import { Link } from 'react-router-dom'

const config = {
  preFooter: () => ' ',
  columns: [
    {
      header: 'Softy Labs sp. z o.o.',
      items: [
        () => (
          <div className="footerRow">
            <a className="white" href="https://maps.google.com/?q=Obozowa 57, 01-161, Warszawa">
              <img style={{ width: '25px', height: '25px', marginRight: '10px' }} src={pin} />
            </a>
            <div className="footerCol">
              <a className="white" href="https://maps.google.com/?q=Obozowa 57, 01-161, Warszawa">
                Warsaw (HQ)
              </a>
              <a className="white" href="https://maps.google.com/?q=Obozowa 57, 01-161, Warszawa">
                Obozowa 57, 01-161
              </a>
            </div>
          </div>
        ),
        () => <div className="verticalSpacer_10" />,
        () => (
          <div className="footerRow">
            <a className="white" href="https://maps.google.com/?q=Okrzei 22/2, Kielce">
              <img style={{ width: '25px', height: '25px', marginRight: '10px' }} src={pin} />
            </a>
            <div className="footerCol">
            <a className="white" href="https://maps.google.com/?q=Okrzei 22/2, Kielce">
              Kielce
            </a>
            <a className="white" href="https://maps.google.com/?q=Okrzei 22/2, Kielce">
            Okrzei 22, 25-525
          </a>
            </div>
          </div>
        ),
        () => <div className="verticalSpacer_10" />,
        () => (
          <div className="footerRow">
            <a className="white" href="https://maps.google.com/?q=Szczecińska 42, 75-137, Koszalin">
              <img style={{ width: '25px', height: '25px', marginRight: '10px' }} src={pin} />
            </a>
            <div className="footerCol">
            <a className="white" href="https://maps.google.com/?q=Szczecińska 42, 75-137, Koszalin">
            Koszalin
          </a>
          <a className="white" href="https://maps.google.com/?q=Szczecińska 42, 75-137, Koszalin">
            Szczecińska 42, 75-137
          </a>
            </div>
          </div>
        ),
      ],
    },
    {
      header: 'Company',
      items: [() => 'VAT-ID: PL675-152-99-51', () => 'REGON: 363347828', () => 'KRS: 0000594606'],
    },
    {
      header: 'Contact',
      items: [
        () => (
          <a className="white" href="mailto:hello@softylabs.com">
            hello@softylabs.com
          </a>
        ),
        () => (
          <a className="white" href="tel:+48665002909">
            +48 665 002 909
          </a>
        ),
        () => <div className="verticalSpacer_10" />,
        () => 'Recruitment:',
        () => (
          <a className="white" href="mailto:joinus@softylabs.com">
            joinus@softylabs.com
          </a>
        ),
      ],
    },
    {
      header: 'Site map',
      items: [
        () => (
          <Link className="white" to="/">
            Home
          </Link>
        ),
        () => (
          <Link className="white" to="/HowWeWork">
            How we work
          </Link>
        ),
        // () => (
        //   <Link className="white" to="/CaseStudy">
        //     Case study
        //   </Link>
        // ),
        () => (
          <Link className="white" to="/Capabilities">
            Capabilities
          </Link>
        ),
        () => (
          <Link className="white" to="/OurStory&Team">
            Our story & team
          </Link>
        ),
        () => (
          <Link className="white" to="/JoinUs">
            Join us{' '}
            <Badge className="pinkBg" href="/JoinUs">
              We're hiring!
            </Badge>
          </Link>
        ),
      ],
    },
    // {
    //   header: 'Reviews',
    //   items: [() => 'Review 1', () => 'Review 2', () => 'Review 3'],
    // },
    ,
  ],
}

export default class Footer extends React.Component {
  renderColumn({ header, items }) {
    return (
      <div className="footerColumn">
        {header ? <div className="footerColumnHeader">{header}</div> : null}
        {items.map((i) => (
          <div className="footerColumnItem"> {i()}</div>
        ))}
      </div>
    )
  }
  render() {
    const { preFooter, columns } = config
    return (
      <>
        {preFooter ? (
          <div className="preFooter">
            <PageWidthWrapper>{preFooter()}</PageWidthWrapper>
          </div>
        ) : null}
        <div className="footer">
          <PageWidthWrapper>
            <div className="footerContent">{columns.map(this.renderColumn)}</div>
          </PageWidthWrapper>
        </div>
      </>
    )
  }
}
