import React from 'react'
import PageWidthWrapper from '../components/PageWidthWrapper'

export default class Section extends React.Component {
  render() {
    const { children, photo, inverted, height, maxWidth, smallPhoto, photo2x } = this.props
    return (
      <>
        <div className="section" style={{ height }}>
          <div className="section_photo_wrapper">
            <img className={`${inverted ? 'inverted_section_photo' : 'section_photo'} ${smallPhoto ? 'smallPhoto' : ''}` } src={photo} srcset={`${photo2x} 2x`}/>
          </div>
          <PageWidthWrapper>
            <div className={inverted ? 'inverted_section_content' : 'section_content'} style={{ maxWidth }}>
              {children}
            </div>
          </PageWidthWrapper>
        </div>
      </>
    )
  }
}
