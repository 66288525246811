import React from 'react'
import Helmet from 'react-helmet'

import JobsOpenings from '../parts/JobsOpenings'
import Footer from '../parts/Footer'

import Section from '../components/Section'
import Article from '../components/Article'
import LineSeparator from '../components/LineSeparator'
import VerticalSpacer from '../components/VerticalSpacer'

import join_us2 from '../assets/premium/join_us2.jpg'
import join_us from '../assets/premium/join_us.jpg'
import join_us22x from '../assets/premium2x/join_us2.jpg'
import join_us2x from '../assets/premium2x/join_us.jpg'

const commonButtonProps = {
  pill: true,
  size: 'sm',
}

export default () => (
  <>
    <Helmet title="Join us" />
    <Section photo={join_us} photo2x={join_us2x}>
      <>
        <VerticalSpacer />
        <Article header={'Why SoftyLabs?'}>
          We commit ourselves to projects challenging and developing for the Team. We are passionate about new
          technologies, tools <br></br>and frameworks and always try to take the best of them and use in our projects.
        </Article>
        <VerticalSpacer />
      </>
    </Section>

    <Section inverted photo={join_us2} photo2x={join_us22x}>
      <VerticalSpacer />

      <Article
      // buttonProps={commonButtonProps}
      // buttonText="Join us"
      >
        <p>
          We see learning as a one of most important process in our lives. The tech-world changes so rapidly that it is
          essential to be up to date with the latest trends. We share our experience <br></br>and encourage you to expand your
          skills <br></br>and knowledge.
        </p>
        <p>
          We take care of friendly atmosphere where everyone feels appreciated and belongs to the organization. We
          value transparent and open forms of communication. We know how important it is to listen to every person’s
          ideas.
        </p>
        <VerticalSpacer height={50} />
      </Article>
      <VerticalSpacer />
    </Section>

    <LineSeparator />

    <div id="Openings" />
    <JobsOpenings />

    <Footer />
  </>
)
