import React from 'react'
import { Card, Button } from 'shards-react'
import GradientBar from '../components/GradientBar'

export default class StartProject extends React.Component {
  render() {
    return (
      <div className="startProject">
        <Card className="startProject_card">
          <h1 className="startProject_card_header">Start a Project with SoftyLabs</h1>
          <div>
            <Button block={false} pill className={'white'} href={'mailto:hello@softylabs.com'}>
              Contact us
            </Button>
          </div>
        </Card>
      </div>
    )
  }
}
