import React from 'react'


import logo from '../assets/logo.png'


export default () => (
  <div className="logo">
    <img src={logo} style={{height: '42px'}}/>
    {/* <div className="logoMain">SoftyLabs</div>
    <div className="logoDegree">°</div> */}
  </div>
)
