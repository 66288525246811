import React from 'react'
import { Card, CardHeader, CardTitle, CardImg, CardBody, CardFooter, Button } from 'shards-react'

export default class StepCard extends React.Component {
  render() {
    const { header, subheader, subheaderClassName, children } = this.props
    return (
      <Card className="stepCard">
        <div className={`subheader ${subheaderClassName ? subheaderClassName : ''}`}>{subheader}</div>
        <h2 className="stepCard_header">{header}</h2>
        <div className="stepCard_line" />
        {children}
      </Card>
    )
  }
}
