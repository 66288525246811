import React from 'react'
import { Button } from 'shards-react'

export default class Article extends React.Component {
  render() {
    const {
      header,
      subheader,
      subheaderClassName,
      smallHeader,
      buttonProps,
      buttonText,
      noMargin,
      description,
      description2,
      children,
      renderHeader,
      renderAboveHeader,
    } = this.props
    return (
      <div className={`article ${noMargin ? 'noMargin' : ''}`}>
        <div className={`subheader ${subheaderClassName ? subheaderClassName : ''}`}>{subheader}</div>
        {renderAboveHeader != null ? renderAboveHeader() : null}
        {renderHeader != null ? (
          renderHeader(header)
        ) : (
          <h2 className={`article_header ${smallHeader ? 'smallHeader' : ''}`}>{header}</h2>
        )}
        <div className="article_description">{description}</div>
        <div className="">{description2}</div>
        {children}
        <div>{buttonProps ? <Button {...buttonProps}>{buttonText}</Button> : null}</div>
      </div>
    )
  }
}
