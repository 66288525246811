import React from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  ModalFooter,
  Form,
  FormInput,
} from "shards-react";
import VerticalSpacer from "../components/VerticalSpacer";

const FORM_ENDPOINT = "https://smartforms.dev/submit/5fce02fdb81854118fd3ef12";

export default class Modall extends React.Component {
  render() {
    const { position, city } = this.props;

    return (
      <Form action={FORM_ENDPOINT} method="POST" enctype="multipart/form-data">

        <Modal
          centered
          backdropClassName="modalBackdrop"
          open={this.props.open}
          toggle={this.props.toggle}
          size="lg"
        >
          <ModalHeader className="modalHeader">
            <h2 className="header">{this.props.title || ""}</h2>
            {this.props.toggle != null
              ? <div className="close" onClick={() => this.props.toggle()} />
              : null}
          </ModalHeader>
          <ModalBody>
            {this.props.content ? this.props.content() : null}
            <>
              <h3 className="header">Formularz aplikacyjny</h3>
              <VerticalSpacer height={20} />
              <div className="formRow">
                <div className="formLabel">Pozycja (Miasto)</div>
                <FormInput
                  // disabled
                  name="Pozycja"
                  value={position != null && city != null
                    ? `${position} (${city})`
                    : "error"}
                />

              </div>
              <div className="formRow">
                <div className="formLabel">Imię*</div>
                <FormInput placeholder="Twoje imię" name="Imię" required />
              </div>
              <div className="formRow">
                <div className="formLabel">Nazwisko*</div>
                <FormInput
                  placeholder="Twoje nazwisko"
                  name="Nazwisko"
                  required
                />
              </div>
              <div className="formRow">
                <div className="formLabel">Email*</div>
                <FormInput
                  placeholder="Twój adres email"
                  name="Email"
                  required
                />
              </div>
              <div className="formRow">
                <div className="formLabel">Numer telefonu</div>
                <FormInput placeholder="Twój numer telefonu" name="Telefon" />
              </div>
              <div className="formRow">
                <div className="formLabel">Twoje CV*</div>
                <input
                  type="file"
                  accept="application/pdf"
                  name="CV"
                  id="CV"
                  required
                />
              </div>

              <small className="formRow">
                <i>Pola oznaczone * są wymagane</i>
              </small>
              <div style={{ marginBottom: "15px" }}>
                Jeśli chcesz, by Twoja aplikacja była uwzględniana z przyszłych
                procesach rekrutacyjnych, prosimy o zaznaczenie następującej
                klauzuli:
              </div>

              <div className="formRow alignTop">
                <div className="formLabel alignRight checkboxPadding">
                  <input
                    type="checkbox"
                    name="FutureRecruitments"
                    id="FutureRecruitments"
                  />
                </div>
                <small>
                  <div>
                    Wyrażam zgodę na przetwarzanie moich danych osobowych
                    zawartych w aplikacji o pracę na potrzeby przyszłych
                    procesów rekrutacyjnych. Jestem świadomy/a, że mam prawo do
                    wycofania zgody w każdym czasie.
                  </div>
                  <div>
                    Wycofanie zgody nie ma wpływu na zgodność z prawem
                    przetwarzania dokonanego przed jej wycofaniem.{" "}
                  </div>
                </small>
              </div>
            </>
          </ModalBody>
          <ModalFooter>
            <Button type="submit">Aplikuj</Button>
          </ModalFooter>
        </Modal>
      </Form>
    );
  }
}
