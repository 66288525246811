import React from 'react'
import PageWidthWrapper from '../components/PageWidthWrapper'

import LineSeparator from '../components/LineSeparator'
import VerticalSpacer from '../components/VerticalSpacer'

const items = [
  'We respect each other and the world we live in',
  'We empower people to be their best',
  'We constantly improve our skills and expand knowledge',
  'We communicate proactively and honestly',
  'We do business with the future in mind',
]

export default class StoryList extends React.Component {
  render() {
    return (
      <>
        <PageWidthWrapper>
          <VerticalSpacer height={50}/>

          <div className="storyList">
            <div className="center600">
              <h3>
                The essence of our company <br></br> is a community of employees <br></br> based on a flat organizational structure <br></br> and
                values we share:
              </h3>
            </div>
            <VerticalSpacer  height={50}/>


        {items.map((i, idx) => (
          <>
            <div className="center600">
              <div className="listItem">
                <div className="counter lightBlueText">0{idx + 1}_</div>
                <div className="listItem_content">{i}</div>
              </div>
            </div>
            <LineSeparator />
          </>
        ))}
          </div>
        </PageWidthWrapper>
      </>
    )
  }
}
