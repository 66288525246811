import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Helmet from "react-helmet";

import routes, { NoMatch } from "./pages";
import Navigation from "./components/Navigation";
import ScrollToTop from "./components/ScrollToTop";

import "./styles/index.css";

class App extends Component {
  render() {
    const [homeRoute, ...otherRoutes] = routes;
    return (
      <Router basename={process.env.PUBLIC_URL}>
        <ScrollToTop />
        <Helmet titleTemplate={`SoftyLabs - %s`} />
        <Navigation
          left={[homeRoute]}
          right={otherRoutes}
          rightEmphasisIdx={4}
        />
        <Switch>
          {routes.map((route, i) => (
            <Route key={i} {...route} />
          ))}
          <Route component={NoMatch} />
        </Switch>
      </Router>
    );
  }
}

export default App;
