import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'

import { Alert } from 'shards-react'


export default class AlertBar extends React.Component {
  render() {
    return (
      <Alert theme="warning" className='alertBar'>
        <h4>
          <Link to="/JoinUs#Openings">We are hiring! Check open positions →</Link>
        </h4>
      </Alert>
    )
  }
}
