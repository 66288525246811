import React from "react";
import StepCard from "../components/StepCard";
import List from "../components/List";
import PageWidthWrapper from "../components/PageWidthWrapper";

const config = [
  {
    header: "Idea",
    subheaderClassName:'lightBlueText',
    lists: [
      {
        header: "Actions".toUpperCase(),
        items: ["Initial business requirements","Broad business description of a problem"],
      },
      {
        header: "Outcomes".toUpperCase(),
        items: ["Possible technical solutions","Finding suitable place in overall architecture"],
      },
    ],
  },
  {
    header: "POC & Prototype",
    subheaderClassName:'lightBlueText',
    lists: [
      {
        header: "Actions".toUpperCase(),
        items: ["Initial materials / assets / brief analysis","Brainstorm workshops"],
      },
      {
        header: "Outcomes".toUpperCase(),
        items: ["User journey on happy path","High level wireframes click-through prototype"],
      },
    ],
  },
  {
    header: "MVP",
    subheaderClassName:'lightBlueText',
    lists: [
      {
        header: "Actions".toUpperCase(),
        items: ["One on one user journey validation", "Layout validation","Look & Feel stabilisation"],
      },
      {
        header: "Outcomes".toUpperCase(),
        items: ["Implemented single user journey",         "Implementation of crucial business logic",         "Identification of edge cases",         "Identification of additional features and scenarios"]  ,
      }
      ],
  },
  {
    header: "Implementation",
    subheaderClassName:'lightBlueText',
    lists: [
      {
        header: "Actions".toUpperCase(),
        items: ["Initiation trainings","Deployment of production version"],
      },
      {
        header: "Outcomes".toUpperCase(),
        items: ["Software implemented in client’s organization","Users feedback"],
      },
    ],
  },
  {
    header: "Pilot/Beta",
    subheaderClassName:'lightBlueText',
    lists: [
      {
        header: "Actions".toUpperCase(),
        items: ["Design/Development based on feedback & tests"],
      },
      {
        header: "Outcomes".toUpperCase(),
        items: ["Test/Beta software versions released "],
      },
    ],
  },
  {
    header: "Release",
    subheaderClassName:'lightBlueText',
    lists: [
      {
        header: "Actions".toUpperCase(),
        items: ["Deployment of production version","UX,UI improvements"],
      },
      {
        header: "Outcomes".toUpperCase(),
        items: ["Production software version released"],
      },
    ],
  },

];

export default class Steps extends React.Component {
  renderLine() {
    const arr = new Array(7).fill(0);
    return (
      <div className="dot">
        {arr.map((i) => (
          <div>●</div>
        ))}
      </div>
    );
  }

  render() {
    return (
      <PageWidthWrapper>
        <div className="steps">
          {config.map((item, idx) => {
            return (
              <>
                <StepCard {...item} subheader={`_0${idx+1}`}>
                  <div className="stepCardContent">
                    {item.lists
                      ? item.lists.map((i) => <List {...i} />)
                      : null}
                  </div>
                </StepCard>
                {idx !== config.length - 1 ? this.renderLine() : null}
              </>
            );
          })}
        </div>
      </PageWidthWrapper>
    );
  }
}
