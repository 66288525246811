import React from 'react'
import VerticalSpacer from "./VerticalSpacer";
export default class JobOffer extends React.Component {
  renderSection({ header, list }) {
    return (
      <div>
        <strong>{header}</strong>
        <ul>
          {list.map((i) => (
            <li>{i}</li>
          ))}
        </ul>
      </div>
    )
  }

  render() {
    const { offer } = this.props
    return (<>
      {offer != null & offer.description != null ? offer.description.map(this.renderSection) : null}
      <VerticalSpacer height={20} />
    </>)
  }
}
