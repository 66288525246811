import React from 'react'
import { Card } from 'shards-react'

import PageWidthWrapper from '../components/PageWidthWrapper'
import List from '../components/List'

import VerticalSpacer from '../components/VerticalSpacer'

import Article from '../components/Article'

export default class Development extends React.Component {
  render() {
    return (
      <PageWidthWrapper>
        <VerticalSpacer />

        <div className="development">
          <Card className="development_mainCard">
            <Article
              noMargin
              header={'Development'}
              description={
                '                Delivering a high-quality product: software that ensures the quality and meets or exceeds customer expectations, reaches completion within times and cost estimates.'
              }
              description2={
                <>
                  We work according to SDLC methodology (Software Development Life Cycle) <br></br> because we believe
                  that dedicated software is the digital part of your company DNA. <br></br> It should live, change and
                  adapt in parallel to business changes.
                </>
              }
            />
            <List
              header={'Areas of expertize'.toUpperCase()}
              items={['Quality Assurance', 'Backend', 'Frontend', 'Mobile Development', 'DevOps']}
            />
          </Card>
          <div className="development_columns">
            <div className="development_row">
              <Card className="development_card">
                <Article
                  noMargin
                  smallHeader
                  header={'Business analysis'}
                  description={
                    'Bridging the gap between IT and the business  using deep analysis to assess processes, determine requirements and deliver optimal recommendations.'
                  }
                  description2={'We want to become part of your business to perfectly understand your needs.'}
                />
              </Card>
              <Card className="development_card">
                <Article
                  noMargin
                  smallHeader
                  header={'UX/UI Design'}
                  description={`Using  various lean methods to solve the user's needs to add value to the business. Explore various design approaches to solve specific user problems. Cooperation on design ideas through workshops, sitemaps, storyboards, and process flows.`}
                  description2={
                    'We believe that the quality of our solutions is determined by happiness of your employees and clients to use them everyday.'
                  }
                />
              </Card>
            </div>
            <div className="development_row">
              <Card className="development_card">
                <Article
                  noMargin
                  smallHeader
                  header={'Project management'}
                  description={
                    'Planning, organizing, and directing the completion of projects - ensuring projects are on time, on budget, and within scope'
                  }
                  description2={
                    'Your goals are our goals - the synergy of our teams and your business strategy is the key to achieve them.'
                  }
                />
              </Card>
              <Card className="development_card">
                <Article
                  noMargin
                  smallHeader
                  header={'Support'}
                  description={
                    <>
                      We want not only provide to your company best solutions, <br></br> but also ensure their
                      effective implementation and further development
                    </>
                  }
                  description2={
                    'Continuous  deployments and constant quality improvements to support further project evolution and business ideas.'
                  }
                />
              </Card>
            </div>
          </div>
        </div>
      </PageWidthWrapper>
    )
  }
}
