import React from 'react'
import ThumbnailGallery from '../components/ThumbnailGallery'
import PageWidthWrapper from '../components/PageWidthWrapper'

import azure from '../assets/logos/azure.png'
import css from '../assets/logos/css.png'
import devops from '../assets/logos/devops.png'
import git from '../assets/logos/git.png'
import html from '../assets/logos/html.png'
import java from '../assets/logos/java.png'
import js from '../assets/logos/js.png'
import junit5 from '../assets/logos/junit5.png'
import kafka from '../assets/logos/kafka.png'
import soapui from '../assets/logos/soapui.png'
import spock2 from '../assets/logos/spock2.png'
import terraform from '../assets/logos/terraform.png'

import android from '../assets/logos/android.png'
import angular from '../assets/logos/angular.png'

import appdynamics from '../assets/logos/appdynamics.png'
import consul from '../assets/logos/consul.png'
import datadog from '../assets/logos/datadog.png'
import docker from '../assets/logos/docker.png'
import ejb from '../assets/logos/ejb.png'
import gradle from '../assets/logos/gradle.png'
import guice from '../assets/logos/guice.png'
import hybernate from '../assets/logos/hybernate.png'
import jboss from '../assets/logos/jboss.png'
import jpa from '../assets/logos/jpa.png'
import kubernets from '../assets/logos/kubernets.png'
import postgresql from '../assets/logos/postgresql.png'
import restfileupload from '../assets/logos/restfileupload.png'
import spring from '../assets/logos/spring.png'
import springboot from '../assets/logos/springboot.png'
import sql from '../assets/logos/sql.png'
import svn from '../assets/logos/svn.png'

const items = [
  {
    header: 'Quality assurance',
    items: [
      {
        src: junit5,
      },
      {
        src: soapui,
      },
      {
        src: spock2,
      },
    ],
  },
  {
    header: 'Backend',
    items: [
      {
        src: spring,
      },
      {
        src: java,
        big: true
      },
      {
        src: kafka,
      },
    ],
    subitems: [jboss, ejb, jpa, guice, hybernate, restfileupload, springboot, sql, postgresql],
  },
  {
    header: 'Frontend & Mobile',
    items: [
      {
        src: html,
      },
      {
        src: css,
      },
      {
        src: js,
      },
    ],
    subitems: [android, angular],
  },
  {
    header: 'DevOps',
    items: [
      {
        src: azure,
      },

      {
        src: devops,
      },

      {
        src: terraform,
      },
    ],
    subitems: [kubernets, datadog, appdynamics, consul, docker],
  },
]

export default class UsedTechnologies extends React.Component {
  render() {
    return (
      <PageWidthWrapper>
        <ThumbnailGallery header="Used technologies" items={items} />
      </PageWidthWrapper>
    )
  }
}
