import styled from 'styled-components'
import PageWidthWrapper from '../PageWidthWrapper'
import NavContent from './NavContent'

const Nav = styled.nav`
  padding: 0.5rem 1rem;
  background: #fff;
  border-bottom: 2px solid #e7e7e7;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 1000;
`

export default (props) => (
  <Nav>
    <PageWidthWrapper>
      <NavContent {...props} />
    </PageWidthWrapper>
  </Nav>
)
