import React from 'react'
import { render } from 'react-snapshot'
import App from './App'
import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css"
import './index.css'

const rootEl = document.getElementById('root')
render(<App />, rootEl)

if (module.hot) {
  module.hot.accept('./App', () => {
    const ReactApp = require('./App').default
    render(<ReactApp />, rootEl)
  })
}
