import React from 'react'
import { Button } from 'shards-react'
import VerticalSpacer from '../components/VerticalSpacer'
import ReactSiema from 'react-siema'

import Section from '../components/Section'
import Article from '../components/Article'
import PageWidthWrapper from '../components/PageWidthWrapper'

import home_trust from '../assets/premium/home_trust.jpg'
import home_responsibility from '../assets/premium/home_responsibility.jpg'
import home_transparency from '../assets/premium/home_transparency.jpg'

import home_trust2x from '../assets/premium2x/home_trust.jpg'
import home_responsibility2x from '../assets/premium2x/home_responsibility.jpg'
import home_transparency2x from '../assets/premium2x/home_transparency.jpg'

export default class OurValues extends React.Component {
  constructor(props) {
    super(props)
    this.state = { currentSlide: 0 }
  }
  renderLine() {
    const arr = new Array(7).fill(0)
    return (
      <div className="horizontal">
        {arr.map((i) => (
          <div className="dot">●</div>
        ))}
      </div>
    )
  }

  renderButton(txt, isPrimary, onClick) {
    return (
      <Button
        active={false}
        onClick={onClick}
        pill
        className="horizontalButton"
        size={'lg'}
        theme={isPrimary ? 'primary' : 'light'}
      >
        {txt}
      </Button>
    )
  }
  render() {
    let slider
    const { currentSlide } = this.state
    return (
      <>
        <div className="center600">
          <div className="center">
            <h3>Everything we do is based on our</h3>
          </div>
          <div className="center">
            <h1>Core Values</h1>
          </div>
        </div>
        <VerticalSpacer height={50} />
        <div className="center horizontal">
          {this.renderButton('Trust', currentSlide === 0, () => {
            slider.goTo(0)
            this.setState({currentSlide: 0})
          })}
          {this.renderLine()}
          {this.renderButton('Transparency', currentSlide === 1, () => {
            slider.goTo(1)
            this.setState({currentSlide: 1})
          })}
          {this.renderLine()}
          {this.renderButton('Responsibility', currentSlide === 2, () => {
            slider.goTo(2)
            this.setState({currentSlide: 2})
          })}
        </div>
        <div>
          <PageWidthWrapper>
            <ReactSiema
              ref={(siema) => {
                slider = siema
              }}
              draggable={false}
            >
              <div>
                <VerticalSpacer />
                <Section photo={home_trust} smallPhoto photo2x={home_trust2x}>
                  <>
                    <VerticalSpacer />
                    <Article
                      header={'Trust'}
                      subheader={'01_'}
                      subheaderClassName="lightBlueText"

                    >
                    <p>
                    Our clients can be assured that we are a stable, honest <br></br>and trustworthy company that delivers what is agreed <br></br>and communicates openly. We also expect our clients to be honest and open towards us as a partner. We treat our employees with respect and focus on providing good working conditions.

                    </p>
                    </Article>
                    <VerticalSpacer />
                  </>
                </Section>
              </div>
              <div>
                <VerticalSpacer />
                <Section photo={home_transparency} smallPhoto photo2x={home_transparency2x}>
                  <>
                    <VerticalSpacer />
                    <Article
                      header={'Transparency'}
                      subheader={'02_'}
                      subheaderClassName="lightBlueText"
                      description={
                        'To us, transparency means WYSIWYG: What you see is what you get. We offer our clients a strategic partnership. We expect that both parties keep each other informed and that we base our partnership on open and visible business processes – business processes that are open and visible to our employees as well.'
                      }
                    />
                    <VerticalSpacer />
                  </>
                </Section>
              </div>
              <div>
                <VerticalSpacer />
                <Section photo={home_responsibility} smallPhoto photo2x={home_responsibility2x}>
                  <>
                    <VerticalSpacer />
                    <Article
                      header={'Responsibility'}
                      subheader={'03_'}
                      subheaderClassName="lightBlueText"

                    >
                      <p>
                      We provide our clients with responsible sourcing. We proactively address any issue in our business relationship to keep our promises, and respect and honour our clients’ confidentiality. <br></br>We trust our employees and are confident that they complete their job assignments professionally.

                      </p>
                    </Article>
                    <VerticalSpacer />
                  </>
                </Section>
              </div>
            </ReactSiema>
          </PageWidthWrapper>
        </div>
      </>
    )
  }
}
