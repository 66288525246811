import React from 'react'
import VerticalSpacer from '../components/VerticalSpacer'
import LineSeparator from '../components/LineSeparator'


class ThumbnailGallerySubitems extends React.Component {
  constructor(props) {
    super(props)
    this.state = { 
      open: false,  
    }
  }

  renderSubitems(items) {
    return (
      <div className="gridGallery_content">
              {items.map((src) => (
                <div className="gridGallery_image_wrapper">
                  <img className="gridGallery_image" src={src} />
                </div>
              ))}
            </div>
    )
  }

  render() {
    return (
      <div className="gridGallery_description">
       <div className="gridGallery_description_trigger" onClick={() => this.setState({open: !this.state.open})}>
         {this.state.open ? 'Hide other technologies' : 'Show other technologies'}
       </div>
       <div className="gridGallery_description_content">
       {this.state.open ? this.renderSubitems(this.props.subitems) : null}
       </div>
       
      </div>
    )
  }
}

export default class ThumbnailGallery extends React.Component {
  render() {
    const { header, items } = this.props
    return (
      <div className="gridGallery">
        <h1 className="gridGallery_header">{header ? header : null}</h1>
        {items.map(({ header, items, subitems }) => (
          <>
            <h3 className="gridGallery_header">{header ? header : null}</h3>
            <div className="gridGallery_content">
              {items.map(({ src, big }) => (
                <div className="gridGallery_image_wrapper">
                  <img className={`gridGallery_image ${big ? 'gridGallery_image_big' : ''}`} src={src} />
                </div>
              ))}
            </div>
            {subitems ? <ThumbnailGallerySubitems subitems={subitems} /> : null}

            <LineSeparator />
            <VerticalSpacer height={20} />
            <VerticalSpacer height={20} />

          </>
        ))}
      </div>
    )
  }
}
