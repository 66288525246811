import React from 'react'
import { render } from 'react-dom'
import { Route, Link } from 'react-router-dom'
import NavLink from './NavLink'

export default class Modall extends React.Component {
  renderNavSection(items, className) {
    return (
      <div className={className}>
        {items.map((i) => (
          <NavLink {...i} />
        ))}
      </div>
    )
  }

  render() {
    const { left, right } = this.props
    return (
      <div className="navContent">
        {left != null ? this.renderNavSection(left, 'navContent_left') : null}
        {right != null ? this.renderNavSection(right, 'navContent_right') : null}
      </div>
    )
  }
}
