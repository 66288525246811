import React from 'react'
import Modal from '../components/Modal'
import JobOffer from '../components/JobOffer'

import { Button } from 'shards-react'
import GradientBar from '../components/GradientBar'
import jobsOpenings from '../fixtures/jobOpenings.json'

export default class JobsOpenings extends React.Component {
  constructor(props) {
    super(props)
    this.state = { open: false, position: '', city: '', offer: null }
  }

  renderOpening(offer) {
    const { city, position } = offer
    return (
      <div className="jobOpening">
        <div className="jobOpeningInfo">
          <div className="subheader">{city}</div>
          <div className="jobOpeningInfo_position">{position}</div>
        </div>
        <div>
        <Button
          className='jobButton'
          pill
          onClick={() => {
            this.setState({
              open: !this.state.open,
              position,
              city,
              offer
            })
          }}
        >
          Apply now
        </Button>
        </div>
      </div>
    )
  }
  render() {
    const { open, position, city, offer } = this.state
    const { header, openings } = jobsOpenings
    return (
      <>
        <GradientBar/>
        <div className="jobOpenings">
          <h1 className="jobOpenings_header">{header}</h1>
          {openings.map((i) => this.renderOpening(i))}
        </div>

        <Modal
          open={open}
          toggle={() => this.setState({ open: !this.state.open })}
          title={position}
          position={position} 
          city={city}
          content={() => <>
          <JobOffer offer={offer}/>
          </>}
        />
      </>
    )
  }
}
