import styled from 'styled-components'

export default styled.div`
  margin: 0 auto;
  @media (min-width: 801px) {
    width: 80vw;
  }
  @media (min-width: 1000px) {
    width: 90vw;
  }
  max-width: 1140px;
`
