import React from 'react'
import Helmet from 'react-helmet'
import Page from '../components/Page'
import { Link } from 'react-router-dom'

export default () => (
  <Page>
    <Helmet title="404" />
    <h1>404?!</h1>
    <Link to={'/'}>Strona główna</Link>
  </Page>
)
