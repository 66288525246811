import React from 'react'
import Helmet from 'react-helmet'

import StartProject from '../parts/StartProject'
import Footer from '../parts/Footer'
import UsedTechnologies from '../parts/UsedTechnologies'
import Development from '../parts/Development'
import VerticalSpacer from '../components/VerticalSpacer'

import Section from '../components/Section'
import Article from '../components/Article'

import GradientBar from '../components/GradientBar'

import capabilities from '../assets/premium/capabilities.jpg'

import capabilities2x from '../assets/premium2x/capabilities.jpg'

const commonButtonProps = {
  pill: true,
  size: 'sm',
  className: 'white',
  href: 'mailto:hello@softylabs.com',
}
export default () => (
  <>
    <Helmet title="Capabilities" />
    <Section photo={capabilities} photo2x={capabilities2x}>
      <>
        <VerticalSpacer />
        <Article
          header={'Capabilities'}
          buttonProps={commonButtonProps}
          buttonText="Contact us"
          description={
            <>
              <p>
                “Any fool can write code that a computer can understand. <br></br>Good programmers write code that
                humans can understand.”
              </p>
              <strong>— Martin Fowler</strong>
            </>
          }
        />
        <VerticalSpacer />
      </>
    </Section>
    <Development />
    <VerticalSpacer />

    <GradientBar />
    <UsedTechnologies />
    <StartProject />
    <Footer />
  </>
)
