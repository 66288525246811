import React from "react";

import Home from "../pages/Home";
import HowWeWork from "../pages/HowWeWork";
import CaseStudy from "../pages/CaseStudy";
import Capabilities from "../pages/Capabilities";
import OurStory from "../pages/OurStory";
import JoinUs from "../pages/JoinUs";
import NoMatch from "../pages/NoMatch";

import Logo from "../components/Logo";
import { Button } from "shards-react";

export default [
  {
    title: "Home",
    path: process.env.PUBLIC_URL + "/",
    component: Home,
    exact: true,
    NavComponent: Logo,
  },
  {
    title: "How we work",
    path: process.env.PUBLIC_URL + "/HowWeWork",
    render: (args) => HowWeWork({ ...args, title: "How we work" }),
  },
  // {
  //   title: 'Case study',
  //   path: '/CaseStudy',
  //   render: (args) => CaseStudy({ ...args, title: 'Case study' }),
  // },
  {
    title: "Capabilities",
    path: process.env.PUBLIC_URL + "/Capabilities",
    render: (args) => Capabilities({ ...args, title: "Capabilities" }),
  },
  {
    title: "Our story & team",
    path: process.env.PUBLIC_URL + "/OurStory&Team",
    render: (args) => OurStory({ ...args, title: "Our story & team" }),
  },
  {
    title: "Join us",
    path: process.env.PUBLIC_URL + "/JoinUs",
    render: (args) => JoinUs({ ...args, title: "Join us" }),
    NavComponent: (args) => (
      <Button className="pinkBg" pill size="sm">
        Join Us
      </Button>
    ),
  },
];

export { NoMatch };
